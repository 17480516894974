import React from 'react';

import { twMerge } from 'tailwind-merge';

interface IPropsBase {
  changeUiOnHover?: boolean;
  'data-testid': string;
}

type TPropsAnchor = IPropsBase &
  React.AnchorHTMLAttributes<HTMLAnchorElement> & { as: 'a'; ref?: React.Ref<HTMLAnchorElement> };
// Button is the default tagName
type TPropsButton = IPropsBase &
  React.ButtonHTMLAttributes<HTMLButtonElement> & {
    as?: 'button';
    ref?: React.Ref<HTMLButtonElement>;
  };

// Pass item to button only.
// It does not make sense to pass item to an anchor
// anchor should only point to a link in href attribute
type IPropsWithItem<T> = Omit<TPropsButton, 'onClick'> & {
  item: T;
  onClick: (item: T) => void;
};

type IPropsWithoutItem = TPropsAnchor | TPropsButton;

type TProps<T> = React.PropsWithChildren<IPropsWithItem<T> | IPropsWithoutItem>;

function IconButton<T>(props: TProps<T>) {
  // exclude item prop from the rest, so it is not passed to the DOM element
  const {
    as = 'button',
    changeUiOnHover = true,
    children,
    className,
    item,
    onClick,
    ...restProps
  } = props;

  function handleClick(e: React.MouseEvent<HTMLButtonElement | HTMLAnchorElement>) {
    // anchor element does not have disabled attribute
    if ('disabled' in props && props.disabled) {
      return;
    }

    // if item is passed, call onClick with item
    if ('item' in props) {
      props.onClick(props.item);
      return;
    }
    if (typeof props.onClick === 'function') {
      // @ts-expect-error TS complains that HTMLAnchorElement and HTMLButtonElement are not compatible
      // But we do not really care, we just need to pass the event to the onClick handler
      props.onClick(e);
    }
  }

  return React.createElement(as, {
    children: React.isValidElement(children)
      ? React.cloneElement(children, { color: 'currentColor', width: 16, ...children.props })
      : null,
    className: twMerge(
      'text-black inline-flex items-center justify-center rounded-sm p-2 cursor-pointer',
      'disabled' in props && props.disabled
        ? 'cursor-not-allowed'
        : changeUiOnHover
          ? 'hover:text-black hover:bg-black-5 dark:text-white dark:hover:bg-white-15'
          : '',
      className
    ),
    ...(as === 'button' && { type: 'button' }),
    ...restProps,
    // keep onClick last so it is not overwritten from props
    // Also use ternary operator to avoid NestJs error
    // "Error: Event handlers cannot be passed to Client Component props."
    // in case of using the component as an anchor tag without onClick
    onClick: onClick ? (handleClick as TProps<T>['onClick']) : undefined,
  });
}

export { IconButton };
